<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="帳號" v-model="qClientUserId">
              <template slot="prepend">帳號</template>
            </el-input>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="名稱" v-model="qDisName">
              <template slot="prepend">名稱</template>
            </el-input>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="電話" v-model="qPhone">
              <template slot="prepend">電話</template>
            </el-input>
        </el-col>   
      </el-row>
      <el-row :gutter="20" style="margin-top:10px">
        <el-col :span="9">
            排序<el-radio-group v-model="qOrderType" size="medium" @change="query">
              <el-radio-button :label="orderType_1"></el-radio-button>
              <el-radio-button :label="orderType_2"></el-radio-button>
              <el-radio-button :label="orderType_3"></el-radio-button>
            </el-radio-group>      
          </el-col>   
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showBatchSentEmail()" v-if="this.$store.getters.userType!='2'">批量發送信件</el-button>
        </el-col>
      </el-row>      
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="clientUserId" label="帳號" width="150"></el-table-column>
        <el-table-column   prop="disName" label="名稱" width="150"></el-table-column>
        <el-table-column   prop="phone" label="電話" width="150">  </el-table-column>
        <el-table-column   prop="registerDate" label="註冊日期" width="180">  </el-table-column>
        <el-table-column   prop="adminNote" label="客戶喜好" width="200">  </el-table-column>
        <el-table-column   prop="ban_" label="黑名單" width="80">  </el-table-column>
        <el-table-column   prop="card4No" label="信用卡末四碼" width="120">  </el-table-column>
        <el-table-column   prop="lineId_" label="Line綁定" width="80"></el-table-column>
        <el-table-column   prop="motoLicenseType" label="駕照類型" width="250"></el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="220">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
            <el-button  @click="showPicDetailForm(scope.row)" type="warning" size="small">證件照片</el-button>
            <el-button v-show="false"  @click="showPayForm(scope.row)" type="warning" size="small">pay</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>

<el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
  <el-form :model="form">
    <el-form-item label="帳號" :label-width="formLabelWidth">
      <el-input v-model="form.clientUserId" autocomplete="off" :readonly="form.mode=='update'"></el-input>
      <el-button :disabled="isLineBound" style="float:left; margin-top:5px; min-width:120px" v-show="form.removeLine == 'T'" type="danger" @click="removeLineBinding()">移除Line綁定</el-button>
      <el-button :disabled="isClientUserBound" style="float:left; margin-top:5px; min-width:120px" v-show="form.removeUser == 'T'" type="danger" @click="removeClientUserBtn(form.clientUserId)">刪除使用者</el-button>
      <el-button style="float:right; margin-top:5px; min-width:120px" v-show="form.ban != 'T'" type="danger" @click="banUser()">加入黑名單</el-button>
      <el-button style="float:right; margin-top:5px; min-width:120px" v-show="form.ban == 'T'" @click="unBanUser()">解除黑名單</el-button>
    </el-form-item>
    <el-form-item label="密碼" :label-width="formLabelWidth">
      <el-input v-model="form.pwd" autocomplete="off" show-password></el-input>
    </el-form-item>
    <el-form-item label="再一次密碼" :label-width="formLabelWidth">
      <el-input v-model="form.pwd2" autocomplete="off" show-password></el-input>
    </el-form-item>
    <el-form-item label="狀態" :label-width="formLabelWidth">
      <el-tag v-if="form.valid && form.country == 'TW'" type="primary">已通過驗證</el-tag>
      <el-tag v-else-if="form.valid && form.country != 'TW'" type="success">已通過驗證</el-tag>
      <el-tag v-else type="danger">未驗證</el-tag>
    </el-form-item>
    <el-form-item label="名稱" :label-width="formLabelWidth">
      <el-input v-model="form.disName" autocomplete="off" ></el-input>
    </el-form-item>
    <el-form-item label="性別" :label-width="formLabelWidth">
      <el-input v-model="form.sex" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="信用卡末4碼" :label-width="formLabelWidth">
      <!-- <el-input v-model="form.card4No" autocomplete="off"></el-input> -->
      {{form.card4No}}
    </el-form-item>
    <el-form-item label="電話" :label-width="formLabelWidth">
      <el-input v-model="form.phone" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="地址" :label-width="formLabelWidth">
      <el-input v-model="form.addr" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="生日" :label-width="formLabelWidth">
      <el-date-picker v-model="form.birthday" type="date" placeholder="">
      </el-date-picker>  
    </el-form-item>
    <el-form-item label="緊急聯絡人" :label-width="formLabelWidth">
      <el-input v-model="form.emrContact" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="緊急聯絡人電話" :label-width="formLabelWidth">
      <el-input v-model="form.emrContactPhone" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="喜好備註" :label-width="formLabelWidth">
      <el-input v-model="form.adminNote" autocomplete="off"></el-input>
    </el-form-item>          
    <el-form-item label="Email" :label-width="formLabelWidth">
      <el-input v-model="form.email" autocomplete="off"></el-input>
      <el-input v-show="false" v-model="form.mode"></el-input>
    </el-form-item>    

    <el-form-item label="綁定信用卡" :label-width="formLabelWidth">
      {{form.boundleCreditCardStatus}}
    </el-form-item>
    <el-form-item label="用戶黑名單原因" :label-width="formLabelWidth">
      {{form.banNote}}
    </el-form-item>
    <el-form-item label="刪除用戶原因" :label-width="formLabelWidth">
      {{form.removeClientUserReason}}
    </el-form-item>
    <el-form-item label="駕照類型" :label-width="formLabelWidth">
      {{form.motoLicenseType}}
    </el-form-item>

  </el-form>    
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditStore" v-loading.fullscreen.lock="fullscreenLoading">確 定</el-button>
  </div>

</el-dialog>
<el-dialog title="照片設定" :visible.sync="dialogPicVisible" style="width:107%;"> 
    <el-row :gutter="20">
      客戶身份證正面<a v-if="pic1" target="_blank" :href="pic1">(開啟大圖)</a>
      <img :src="pic1" class="pic"/>
      <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange">
          <el-button type="primary" @click="uploadFile('1')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">上傳</el-button>
          <el-button type="danger" @click="deleteFile('1',pic1)" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">刪除</el-button>
      </form>
    </el-row>
    <el-row :gutter="20">
      客戶身份證反面<a v-if="pic2" target="_blank" :href="pic2">(開啟大圖)</a>
      <img :src="pic2" class="pic"/>
      <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange">
          <el-button type="primary" @click="uploadFile('2')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">上傳</el-button>
          <el-button type="danger" @click="deleteFile('2',pic2)" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">刪除</el-button>
      </form>
    </el-row>
    <el-row :gutter="20">
      駕照正面<a v-if="pic3" target="_blank" :href="pic3">(開啟大圖)</a>
      <img :src="pic3" class="pic"/>
      <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange">
          <el-button type="primary" @click="uploadFile('3')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">上傳</el-button>
          <el-button type="danger" @click="deleteFile('3',pic3)" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">刪除</el-button>
      </form>
    </el-row>
    <el-row :gutter="20">
      駕照反面<a v-if="pic4" target="_blank" :href="pic4">(開啟大圖)</a>
      <img :src="pic4" class="pic"/>
      <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange">
          <el-button type="primary" @click="uploadFile('4')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">上傳</el-button>
          <el-button type="danger" @click="deleteFile('4',pic4)" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">刪除</el-button>
      </form>
    </el-row>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="backUploadDialog">退出</el-button>
  </div>
</el-dialog>


<el-dialog title="pay視窗" :visible.sync="dialogPayVisible">
  <form method="POST" action="https://ccore.newebpay.com/MPG/mpg_gateway" >
      <input v-model="pform.Amt" name="Amt"/>
      <input v-model="pform.Email" name="Email"/>
      <input v-model="pform.ItemDesc" name="ItemDesc"/>
      <input v-model="pform.LoginType" name="LoginType"/>
      <input v-model="pform.MerchantID" name="MerchantID"/>
      <input v-model="pform.MerchantOrderNo" name="MerchantOrderNo"/>
      <input v-model="pform.RespondType" name="RespondType"/>
      <input v-model="pform.TimeStamp" name="TimeStamp"/>
      <input v-model="pform.TradeInfo" name="TradeInfo"/>
      <input v-model="pform.TradeSha" name="TradeSha"/>
      <input v-model="pform.Version" name="Version"/>
      <input type="submit" value="確定"/>
      <!-- <el-button type="primary" type="submit">支付</el-button> -->
  </form> 
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogPayVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditStore">確 定</el-button>
  </div>

</el-dialog>
<el-dialog title="加入黑名單後，該顧客帳號將被停權無法於所有門市租借車輛" :close-on-click-modal="false" :visible.sync="banAlert" font-size="150%">
  <el-form style=" border-spacing:0px; font-size:200%; text-align:center; ">
    <el-form-item label="備註" :label-width="formLabelWidth">
    <el-input placeholder="備註" v-model="form.banNote"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="banAlert = false">取消</el-button>
    <el-button @click="confirmBanClientUser()" type="primary">確定</el-button>
  </div>
</el-dialog>
  <el-dialog title="批量發送Email" :visible.sync="dialogBatchSentEmailVisible"  width="90%"  :close-on-click-modal="false">
    <el-input type="text" placeholder="標題" v-model="batchEmailTitle"></el-input>
    <el-row>
        <el-col :span="12">
          <el-input type="textarea" placeholder="內文(請使用HTML語法)" style="height:50vh" rows="15" v-model="batchEmailBody"></el-input>
        </el-col>
        <el-col :span="12">
          <el-input type="textarea" placeholder="客戶帳號(請從excel複製帳號再貼上至此)" style="height:50vh" rows="15" v-model="clientListInput"></el-input>
        </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogBatchSentEmailVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmBatchSentEmail()">確 定</el-button>
    </div>
  </el-dialog>
    
  <!-- 顯示使用者現有訂單 視窗-->
    <el-dialog title="該客戶有未完成訂單，請先完成訂單流程後再進行刪除操作" :visible.sync="dialogGetClientOrderNow">
        <div v-for="(order, index) in form.orders" :key="index" >
          <el-card style="margin-bottom: 10px;">
            <p>帳號：{{ order.clientUserId }}</p>
            <p>訂單號：{{ order.clientOrderId }}</p>
            <p>建立時間：{{ order.createDate }}</p>
          </el-card>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogGetClientOrderNow = false">先處理訂單異動</el-button>
        </div> 
    </el-dialog>

  <!-- 刪除使用者原因 視窗-->
    <el-dialog title="請填寫刪除使用者帳號的原因" :visible.sync="dialogRemoveClientReason">
      <el-form>
        <el-form-item label="刪除原因" :label-width="formLabelWidth">
          <el-input v-model="form.removeClientUserReason" placeholder=""></el-input>
        </el-form-item>    
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogRemoveClientReason = false">取消</el-button>
        <el-button type="primary" @click="confirmRemoveClientUser()">確定</el-button>
      </div> 
    </el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
import imageCompression from 'browser-image-compression'
export default {
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      labelPosition: 'right',
      pic1:undefined,pic2:undefined,pic3:undefined,pic4:undefined,
      url1: '../api/register/upload/clientUserId/picType',
      url2: '',
      url3: '',
      url4: '',
      formData:undefined,
      statusList:[
        {code:'0',disName:'啟用'},
        {code:'1',disName:'停用'}
      ],      
        qDisName:'',qClientUserId:'',qPhone:'',
        tableData:[],
        dialogFormVisible: false,
        dialogAuthVisible: false,
        dialogPicVisible:false,
        dialogPayVisible:false,
        dialogRemoveClientReason:false, //"刪除使用者原因"視窗
        dialogGetClientOrderNow:false, //"顯示使用者現有訂單"視窗
        form: {
          clientUserId:'', // 帳號
          removeLine:'T', // 移除Line綁定
          removeUser:'T', // 刪除使用者
          ban:'F', // 黑名單狀態
          pwd:'', // 密碼
          pwd2:'', // 再一次密碼
          valid: false, //狀態
          disName:'', // 名稱
          sex:'', // 性別
          card4No: '', // 信用卡末4碼
          phone:'', // 電話
          addr:'', // 地址
          birthday:'', // 生日
          emrContact:'', // 緊急聯絡人
          emrContactPhone:'', // 緊急聯絡人電話
          adminNote:'', // 喜好備註
          email:'', // Email
          boundleCreditCard:'F', // 綁定信用卡
          boundleCreditCardStatus:'',
          banNote:'', // 黑名單原因
          motoLicenseType:'', // 駕照類型
          removeClientUserReason:'', 
          orders:[]
        },
        payForm:{},
        pform:{},
        formLabelWidth: '120px',
        fullscreenLoading: false,
      imageOption: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      },
      qOrderType:'註冊時間⇡',orderType_1:'註冊時間⇡',orderType_2:'會員帳號⇡',orderType_3:'會員名稱⇡',
      clientUserId: '',
      banAlert: false,
      dialogBatchSentEmailVisible: false,
      batchEmailTitle: '',
      batchEmailBody: '',
      clientListInput: '',
      isLineBound:'',
      isClientUserBound:'',
      newClientUserId:''
    }
  },
  created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });   
  },
  name: 'AdminClientUser',
  components: {
  },
  computed:{
  },
  methods :{
    uploadFile(type){
      let tUploadPath  =this.uploadPath+'/'+type
      let tType=type;
      let tPath = '../api/adminClientUser/getUserPic/clientUser/';
      this.$confirm('是否確定上傳照片','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.fullscreenLoading = true
        this.$axios.post(tUploadPath, this.formData).then((res) => {
          switch(tType){
            case "1":
              this.pic1 = tPath + res.data.img
              break;
            case "2":
              this.pic2 = tPath + res.data.img
              break;
            case "3":
              this.pic3 = tPath + res.data.img
              break;
            case "4":
              this.pic4 = tPath + res.data.img
              break;
          }
        this.fullscreenLoading = false
        this.query();
        }).catch((res) => {
          this.fullscreenLoading = false
          console.log(res)
        });
      })
    },
    async fileChange(e) {
      this.formData=new FormData();
      //放進上傳的檔案
      try {
        const compressedFile = await imageCompression(e.target.files[0], this.imageOption)
        this.formData.append('file', compressedFile)
      } catch (error) {
        console.log(error)
      }
    },    

    backUploadDialog(){
      this.dialogPicVisible = false
      this.query()
    },     
    query(){
      this.$axios
        .get('../api/adminClientUser/getClientUsers?page='+this.pageCurrent,{
          params: {
            clientUserId: this.qClientUserId,
            disName: this.qDisName,
            phone: this.qPhone,
            orderType: this.qOrderType
          }})
        .then((res)=>{
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(clientUser=>{
            if(clientUser.lineId != null && clientUser.lineId != undefined){
              clientUser.lineId_ = 'V'
            } else{
              clientUser.lineId_ = ''
            }
            if(clientUser.ban == 'T'){
              clientUser.ban_ = 'V'
            } else{
              clientUser.ban_ = ''
            }
            switch(clientUser.motoLicense){
              case "M":
                  clientUser.motoLicenseType = '普通重型機車( 150 CC以下)'
                  break
              case "S":
                  clientUser.motoLicenseType = '輕型機車( 50 CC以下)'
                  break
              case "N":
                  clientUser.motoLicenseType = '沒有駕照（微型電動車）'
                  break
              default:
                  clientUser.motoLicenseType = '資料錯誤'
                  break
            }
            clientUser.registerDate = this.$date_(clientUser.registerDate)
            this.tableData.push(clientUser)
          })
          
          this.pageTotal=res.data.pageTotal
      }).catch(()=>{
          this.$alert('服務器連線錯誤', '結果', { confirmButtonText: '確定' });
      })
    },
    confirmEditStore(){
      let url='../api/adminClientUser/updateClientUser'
      let confirmText = '是否確定更新會員資料'
      if(this.form.mode=='create'){
        url='../api/adminClientUser/createClientUser'
        confirmText = '是否確定新增會員'
        this.form.card4No = undefined
      }
      this.form.mode=undefined
      if(this.form.birthday instanceof Date){
        this.form.birthday=moment(this.form.birthday).format('YYYYMMDD')
      }

      this.$confirm(confirmText,'注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.form.clientUserId = this.form.clientUserId.toUpperCase()
        this.fullscreenLoading = true
        this.$axios
          .post(url,
            this.form)
          .then(()=>{
            this.fullscreenLoading = false
            this.query()
            this.dialogFormVisible=false
        }).catch((res)=>{
            this.fullscreenLoading = false
            console.log(res.data);
        });      

      })
    },
    showCreateDetailForm(){
      this.isLineBound = true
      this.isClientUserBound = true
      this.form = Object.assign({}, this.form, {
        mode:'create',
        clientUserId:'',
        removeUser:'T',
        ban:'F',
        pwd:'',
        pwd2:'',
        valid:'',
        disName:'',
        sex:'',
        phone:'',
        addr:'',
        birthday:null,
        emrContact:'',
        emrContactPhone:'',
        email:'',
        boundleCreditCardStatus:'未綁定',
        banNote:'',
        adminNote:'',
        motoLicenseType:''
      })

      this.dialogFormVisible=true
    },
    showPayForm(clientUser){
      this.dialogPayVisible=true
      this.$axios
        .get('../api/adminClientUser/getPayInfo',{params:{price:'200',clientUserId:clientUser.clientUserId}})
        .then((res)=>{
          this.pform = Object.assign({}, this.pform, res.data)
      }).catch(()=>{
          this.$alert('服務器連線錯誤', '結果', { confirmButtonText: '確定' });
      });      
    },    
    showDetailForm(clientUser){
      this.$axios
        .get('../api/adminClientUser/getClientUser?clientUserId='+clientUser.clientUserId)
        .then((res)=>{
          res.data.mode='update'
          res.data.valid = res.data.pwd == 'valid'
          res.data.pwd=''
          res.data.pwd2=''
          if(res.data.birthday != null&&res.data.birthday.match(/\d{4}\d{2}\d{2}/)){
            res.data.birthday=moment(res.data.birthday,'YYYYMMDD').toDate()
          }else{
            res.data.birthday=null
          }
          this.form = Object.assign({},this.form, res.data)
          switch(clientUser.motoLicense){
              case "M":
                  clientUser.motoLicenseType = '普通重型機車( 150 CC以下)'
                  break
              case "S":
                  clientUser.motoLicenseType = '輕型機車( 50 CC以下)'
                  break
              case "N":
                  clientUser.motoLicenseType = '沒有駕照（微型電動車）'
                  break
              default:
                  clientUser.motoLicenseType = '資料錯誤'
                  break
            }
            this.isLineBound = clientUser.lineId === null || clientUser.lineId === undefined ? true : false;

            this.isClientUserBound = clientUser.clientUserId.includes('ER') ? true : false;
            this.newClientUserId = clientUser.clientUserId;

            this.form.motoLicenseType = clientUser.motoLicenseType
          this.dialogFormVisible=true
      }).catch(()=>{
          this.$alert('服務器連線錯誤', '結果', { confirmButtonText: '確定' });
      });
      this.$axios.get('../api/adminClientUser/getClientUsers?clientUserId=' + clientUser.clientUserId).then(res => {
        res.data.data.forEach(e=>{
          this.form.boundleCreditCard = e.boundleCreditCard
          this.form.boundleCreditCardStatus = this.form.boundleCreditCard == 'T' ? '綁定' : '未綁定'
        })
      })
    },
    showPicDetailForm(clientUser){
      if (clientUser.pic1 === null) { clientUser.pic1 = 'clientUser/null'}
      if (clientUser.pic2 === null) { clientUser.pic2 = 'clientUser/null'}
      if (clientUser.pic3 === null) { clientUser.pic3 = 'clientUser/null'}
      if (clientUser.pic4 === null) { clientUser.pic4 = 'clientUser/null'}

      this.pic1 = '../api/adminClientUser/getUserPic/'+clientUser.pic1
      this.pic2 = '../api/adminClientUser/getUserPic/'+clientUser.pic2
      this.pic3 = '../api/adminClientUser/getUserPic/'+clientUser.pic3
      this.pic4 = '../api/adminClientUser/getUserPic/'+clientUser.pic4

      this.uploadPath= '../api/adminClientUser/upload/' + clientUser.clientUserId;    
      this.url1='../api/adminClientUser/upload/'+clientUser.clientUserId+'/1'
      this.url2='../api/adminClientUser/upload/'+clientUser.clientUserId+'/2'
      this.url3='../api/adminClientUser/upload/'+clientUser.clientUserId+'/3'
      this.url4='../api/adminClientUser/upload/'+clientUser.clientUserId+'/4'
      this.clientUserId = clientUser.clientUserId
      this.dialogPicVisible=true
    },
    fter_status(row, column) {
      let status = row[column.property];
      if (status == undefined)return "";
      for(var i in this.statusList){
        if(this.statusList[i].code==status){
          return this.statusList[i].disName
        }
      }
      return ""
    },  
    deleteFile(type,pic) {
      let deleteUrl = '../api/adminClientUser/deleteClientUserPic'
      let param = {
        clientUserId: this.clientUserId,
        type: 'pic'+type
      }
      this.$confirm('是否確定刪除證件照片','注意',{confirmButtonText:'確定',cancelButtonText:'取消'})
      .then( ()=>{
        if (pic === '../api/adminClientUser/getUserPic/clientUser/null') {
        this.$alert('刪除失敗，此證件照片不存在','注意',{confirmButtonText:'確定'})
        return
      }
      this.fullscreenLoading = true
        this.$axios.post(deleteUrl,param)
        .then((res)=>{
          this.fullscreenLoading = false
          if(res.data.rs === 'success') {
            this.$alert('照片刪除成功','結果',{confirmButtonText:'確定'})
            switch (type){
              case '1':
                this.pic1 = '../api/adminClientUser/getUserPic/clientUser/null'
                break
              case '2':
                this.pic2 = '../api/adminClientUser/getUserPic/clientUser/null'
                break
              case '3':
                this.pic3 = '../api/adminClientUser/getUserPic/clientUser/null'
                break
              case '4':
                this.pic4 = '../api/adminClientUser/getUserPic/clientUser/null'
                break
            }
          } else {
            this.$alert(res.data.rsMsg,'注意',{confirmButtonText:'確定'})
          }
        }).catch(err => {
          console.log(err)
          this.$alert('不知名錯誤','注意',{confirmButtonText:'確定'})
          this.fullscreenLoading = false
        })

      })
    },
    banUser() {
      this.banAlert = true
    },
    confirmBanClientUser(){
      let pa = { banNote: this.form.banNote,
                clientUserId: this.form.clientUserId }
      this.$axios.post('../api/adminClientUser/banClientUser',pa).then(res => {
        if(res.data.rs == 'success') {
          this.$alert('加入黑名單成功','結果',{confirmButtonText:'確定'})
        } else {
          this.$alert('加入黑名單失敗','結果',{confirmButtonText:'確定'})
        }
        this.banAlert = false 
        this.showDetailForm(this.form)
      })
    },
    unBanUser() {
      this.$confirm('解除黑名單後，該顧客帳號將可於所有門市租借車輛','',{confirmButtonText:'',cancelButtonText:''}).then(()=>{
        let pa = { clientUserId: this.form.clientUserId }
        this.$axios.post('../api/adminClientUser/unbanClientUser',pa).then(res => {
        if(res.data.rs == 'success') {
          this.$alert('解除黑名單成功','結果',{confirmButtonText:'確定'})
        } else {
          this.$alert('解除黑名單失敗','結果',{confirmButtonText:'確定'})
        }
        this.showDetailForm(this.form)
        })
      })
    },
    confirmBatchSentEmail () {
      let textLine = this.clientListInput.split("\n")
      let confirmMessage = '是否確定批量發送Email'
      this.$confirm(confirmMessage,'注意',{confirmButtonText:'確定',cancelButtonText:'取消'}).then(()=>{
        this.fullscreenLoading = true
        let param = {
          body: this.batchEmailBody,
          title: this.batchEmailTitle,
          clientUserList: textLine
        }
        this.$axios
          .post("../api/adminClientUser/batchSentEmail", param)
            .then((res) => {
              this.fullscreenLoading = false;
              if (res.data.rs ==="success") {
                this.$alert('發送請求成功','結果',{confirmButtonText:'確定'})
                this.dialogBatchSentEmailVisible = false
              } else {
                this.$alert(res.data.rsMsg,'結果',{confirmButtonText:'確定'})
              }
          }).catch(() =>{
            this.$alert('發生不知名錯誤','結果',{confirmButtonText:'確定'})
            this.fullscreenLoading = false;
          })
      })
    },
    showBatchSentEmail () {
      this.dialogBatchSentEmailVisible = true
      this.batchEmailBody = ''
      this.batchEmailTitle = ''
      this.clientListInput = ''
    },

    // 移除Line綁定
    removeLineBinding (){
      let param = {
        clientUserId: this.form.clientUserId 
      }
      this.$confirm('確定要解除Line綁定 ?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          this.$axios.post('../api/adminClientUser/removeLineBinding', param).then(res => {
            if(res.data.rs == 'success'){
              this.$message({
                type:'success',
                message:'解除成功'
              });
              this.getNewLineId()
            } else {
              // removeLineBinding 的 res.data.rs 等於 'fail'
              this.$alert('解除Line綁定失敗','結果',{confirmButtonText:'確定'})  
            }
          }).catch(error => {
            console.error('解除Line_請求失敗', error);
          })
      }).catch(() => {
          this.$message({
            type:'info',
            message:'取消'
          });
      });
    },

    // 刪除使用者按鈕_先檢查是否有訂單
    removeClientUserBtn(clientUserId){
      this.$axios.get('../api/adminClientUser/getClientOrderNow', {
        params:{clientUserId: clientUserId }
      }).then(res => {
        if (res.data.rs === 'orderExists') {
          this.dialogGetClientOrderNow = true // 顯示已有訂單
          this.form.orders = res.data.data.map(e => ({
            clientUserId: e.clientUserId,
            clientOrderId: e.clientOrderId,
            createDate: moment(e.createDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
          }))
        } else if (res.data.rs === 'noOrderExist') {
          this.dialogRemoveClientReason = true; // 填寫刪除原因
          this.form.removeClientUserReason = '';
        } else {
          this.$alert('未知錯誤，請通知資訊人員', '錯誤', { confirmButtonText: '確定' });
        }
      })
    },

    // 刪除使用者_確認
    confirmRemoveClientUser(){
      let param = {
        clientUserId: this.form.clientUserId,
        removeClientUserReason: this.form.removeClientUserReason.trim()
      }

      if (param.removeClientUserReason === "") {
        this.$alert('請填寫原因', '結果', { confirmButtonText: '確定' });
        return;
      }

      this.$confirm('確定要刪除使用者 ?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        this.$axios.post('../api/adminClientUser/removeClientUser', param).then(res => {
          if(res.data.rs == 'success'){
            this.$notify({
                title: '結果',
                message: res.data.rsMsg, // 刪除成功
                type: 'success',
                duration: 4500,
                position: 'top-right',
                showClose: true
            })
            this.getNewClientUserId()
            this.dialogRemoveClientReason = false
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' }); // 後端參數錯誤、後端執行錯誤
            this.dialogRemoveClientReason = false
          }
        }).catch(() => {
          this.$alert('不知名錯誤，請通知資訊人員', '結果', { confirmButtonText: '確定' })
        }).finally(() => {
          this.dialogRemoveClientReason = false;
        });
      })
    },

    // 刪除使用者_填寫原因
    removeClientUserReason(){
      let param = {
        clientUserId: this.form.clientUserId,
        removeClientUserReason: this.form.removeClientUserReason.trim()
      }
      this.$axios.post('../api/adminClientUser/removeClientUserReason', param).then(res => {
        if(res.data.rs !== 'success') {
          this.$alert('填寫原因失敗','結果',{confirmButtonText:'確定'})
        }
      }).catch(error => {
        console.error('填寫原因_請求失敗', error);
      });
    },
    
    getNewLineId(){
      const url = `../api/adminClientUser/getNewLineId`;
      const params = {
        clientUserId: this.form.clientUserId 
      };
      this.$axios.get(url, { params }).then(res => {
        let lineId = res.data.lineId
        this.isLineBound = lineId === null || lineId === undefined ? true : false;
      }).catch(error =>{
        console.error('查詢lineId_請求失敗', error);
      })
      this.query();  
    },

    getNewClientUserId(){
      this.isClientUserBound = true
      this.query();
    }
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #ad9393;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #2e2e2e;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 250px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 400px;
    height: 300px;
    display: block;
  }
  .pic{
  width: 640px;
  height:480px;
  }
  .clientOrderForm{
    margin-bottom: 8px; 
    padding: 5px;
    border-style: none;
    border-radius: 5px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
                0 1px 3px rgba(0, 0, 0, 0.08);
  }
</style>